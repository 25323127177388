












































import { defineComponent } from '@vue/composition-api'

const BottomSheet = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    },
    allowAssign: {
      type: Boolean,
      required: false,
      default: true
    },
    allowExpansion: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowByHistory: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})

export default BottomSheet
