















































































































import { defineComponent, ref, computed, watch } from '@vue/composition-api'
import moment from 'moment'

import { endpoints, showError } from 'utils'
import { useQuery, useShowError, useMutation } from 'hooks'
import { CommonProperty } from 'typings'

import { LoaderComponent } from 'components'

const AutoAssignDialog = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    },
    auctionDate: {
      type: String,
      required: true
    },
    dateFrom: {
      type: String,
      required: false
    },
    dateTo: {
      type: String,
      required: false
    }
  },
  components: {
    LoaderComponent
  },
  setup(props, { root, emit }) {
    const { $toast } = root

    const selected = ref([])
    const startDate = ref(props.dateFrom || moment().format('YYYY-MM-DD'))
    const endDate = ref(props.dateTo || moment().format('YYYY-MM-DD'))

    const startDateModal = ref(false)
    const endDateModal = ref(false)

    const enpointOrderType = computed(() => {
      return props.value ? endpoints.ORDER_TYPES : null
    })

    const { data: orderTypes, loading, error } = useQuery(() => enpointOrderType.value)
    useShowError($toast, error)

    const [autoAssign, { loading: autoAssignLoading }] = useMutation(
      'post',
      `${endpoints.ASSIGNMENTS}auto_assign`
    )

    const selectAll = computed(() => {
      return selected.value.length === orderTypes.value.length
    })

    const handleSelectAll = (value: boolean) => {
      selected.value = []

      if (value) {
        selected.value = orderTypes.value.map((type: CommonProperty) => type.id)
      }
    }

    const handleAutoAssign = async (): Promise<void> => {
      try {
        const data = await autoAssign({
          startDate: startDate.value,
          endDate: endDate.value,
          auctionDate: props.auctionDate,
          orderTypeIds: selected.value
        })

        $toast.success(
          root.$t('assign.auto_assign.success', {
            number: data.assignedOrder,
            order_number: data.order
          })
        )
        emit('input', false)
        if (data.assignedOrder) {
          emit('on-finished')
        }
      } catch (e) {
        showError(e, $toast, root.$t('assign.auto_assign.failed'))
      }
    }

    watch(props, () => {
      startDate.value = props.dateFrom || moment().format('YYYY-MM-DD')
      endDate.value = props.dateTo || moment().format('YYYY-MM-DD')
    })

    return {
      startDate,
      endDate,
      selected,
      orderTypes,
      loading,
      autoAssignLoading,
      selectAll,
      handleSelectAll,
      handleAutoAssign,
      startDateModal,
      endDateModal
    }
  }
})
export default AutoAssignDialog
